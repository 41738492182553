import { useState, useEffect, useRef } from 'react';

const LocationMap = ({ locationData }) => {
  console.log("locationData (raw):", locationData);
  console.log("locationData type:", typeof locationData);
  
  // Use ref to store the last valid coordinates
  const lastValidCoordinates = useRef({
    latitude: "",
    longitude: ""
  });
  
  // State for coordinates to display
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: ""
  });
  
  useEffect(() => {
    if (!locationData) return;
    
    try {
      // Parse the string into an object if it's a string
      let locationObject;
      
      if (typeof locationData === 'string') {
        locationObject = JSON.parse(locationData);
        console.log("Parsed location object:", locationObject);
      } else {
        locationObject = locationData;
      }
      
      // Check if we have valid data after parsing
      if (locationObject && locationObject.latitude && locationObject.longitude) {
        const newCoords = {
          latitude: locationObject.latitude,
          longitude: locationObject.longitude
        };
        
        // Store as last valid coordinates
        lastValidCoordinates.current = newCoords;
        
        // Update state
        setCoordinates(newCoords);
        console.log("Updated coordinates:", newCoords);
      }
    } catch (error) {
      console.error("Error parsing location data:", error);
      
      // If parsing fails but we have previous valid coordinates, keep using them
      if (lastValidCoordinates.current.latitude && lastValidCoordinates.current.longitude) {
        setCoordinates(lastValidCoordinates.current);
      }
    }
  }, [locationData]);

  // Only create map URL if we have valid coordinates
  const mapUrl = coordinates.latitude && coordinates.longitude 
    ? `https://maps.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&z=15&output=embed`
    : '';
  
  // Don't render the component if no coordinates are available
  if (!mapUrl) {
    return null;
  }
  
  return (
    <div className="tw-w-full tw-mx-auto tw-rounded-lg tw-overflow-hidden tw-shadow-lg">
      <div className="tw-bg-[#03335B] tw-text-white tw-py-1 tw-px-4">
        <h3 className="tw-text-lg tw-font-semibold tw-mb-1">Location</h3>
        <p className="tw-text-sm tw-mb-0">
          {coordinates.latitude}, {coordinates.longitude}
        </p>
      </div>
      <div className="tw-relative tw-w-full tw-h-52">
        <iframe
          src={mapUrl}
          className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-border-0"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Location Map"
        ></iframe>
      </div>
      {/* <div className="tw-bg-[#03335B] hover:tw-bg-[#1279BE] tw-px-4 tw-py-1">
        <a 
          href={`https://maps.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
          className="tw-text-white tw-text-sm"
        >
          View larger map
        </a>
      </div> */}
    </div>
  );
};

export default LocationMap;