import common_axios from "./commonAxios";


let baseURL = common_axios.defaults.baseURL + 'carenavigator/';


export const dashboardPendingThisWeek = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'dashboard_pending_this_week', params, cancelToken)
    return dataPass;
};

export const fetchWeeklySummary = (weekCount, cancelToken) => {
    const params = { 'weekCount': weekCount }
    var dataPass = common_axios.post(baseURL + 'fetch_weekly_summary', params, cancelToken)
    return dataPass;
};

export const fetchUsersList = (appliedFilter, searchStr, pageNumber, cancelToken, messageStatus) => {
    const params = { 'appliedFilter': appliedFilter, 'searchStr': searchStr, 'pageNumber': pageNumber, 'messageStatus': messageStatus }
    var dataPass = common_axios.post(baseURL + 'fetch_users_list_v2', params, cancelToken)
    return dataPass;
};

export const fetchFamilyList = (appliedFilter, searchStr, pageNumber, cancelToken, messageStatus) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'messageStatus': messageStatus }
    var dataPass = common_axios.post(baseURL + 'fetch_users_family_list', params, cancelToken)
    return dataPass;
};

export const fetchApiInsight = () => {
    var dataPass = common_axios.get(baseURL + 'fetch_new_insight')
    return dataPass;
};


export const searchMedicationDrugs = (searchStr, cancelToken) => {
    const params = { 'searchStr': searchStr }
    var dataPass = common_axios.post(baseURL + 'search_medication_drugs', params, cancelToken)
    return dataPass;
};

export const searchMedicationDrugsMetaDoc = (searchStr, cancelToken) => {
    const params = { 'searchStr': searchStr }
    var dataPass = common_axios.post(baseURL + 'search_medication_drugs_metadoc', params, cancelToken)
    return dataPass;
};

export const fetchAssessmentCategories = (categoryType, cancelToken) => {
    const params = { 'categoryType': categoryType }
    var dataPass = common_axios.post(baseURL + 'fetch_assessment_categories', params, cancelToken)
    return dataPass;
};

export const fetchAssessmentServices = (categoryId, cancelToken) => {
    const params = { 'categoryId': categoryId }
    var dataPass = common_axios.post(baseURL + 'fetch_assessment_services', params, cancelToken)
    return dataPass;
};

export const addAssessment = (userId, formData, version, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData, 'version': version }
    var dataPass = common_axios.post(baseURL + 'add_assessment', params, cancelToken)
    return dataPass;
};

export const fetchFiltersList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'filterslist', params, cancelToken)
    return dataPass;
};

export const fetchPieData = (pieGraphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + pieGraphType, params, cancelToken)
    return dataPass;
};

export const fetchColumnData = (graphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + graphType, params, cancelToken)
    return dataPass;
};

export const fetchLineData = (graphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + graphType, params, cancelToken)
    return dataPass;
};

export const fetchTodaysAppointmentsCount = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_todays_appointments_count', params, cancelToken)
    return dataPass;
};

export const fetchTodayAppointmentsList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_today_appointments_list', params, cancelToken)
    return dataPass;
};

export const fetchOtherTasksList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_other_tasks_list', params, cancelToken)
    return dataPass;
};


export const fetchWorkingPlansAndBreaks = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_working_plans_and_breaks', params, cancelToken)
    return dataPass;
};

export const saveAppointmentBreak = (date, start, end, reason, cancelToken) => {
    const params = { 'date': date, 'start': start, 'end': end, 'reason': reason }
    var dataPass = common_axios.post(baseURL + 'save_appointment_break', params, cancelToken)
    return dataPass;
};

export const saveAppointmentSlots = (slotDay, slotString, slotCount, cancelToken) => {
    const params = { 'slotDay': slotDay, 'slotString': slotString, 'slotCount': slotCount }
    var dataPass = common_axios.post(baseURL + 'save_appointment_slots', params, cancelToken)
    return dataPass;
};

export const removeAppointmentBreak = (breakId, cancelToken) => {
    const params = { 'breakId': breakId }
    var dataPass = common_axios.post(baseURL + 'remove_appointment_break', params, cancelToken)
    return dataPass;
};

export const fetchCalendarCalls = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_calendar_calls', params, cancelToken)
    return dataPass;
};


export const fetchUserProfile = (userId, requestFrom = '', cancelToken) => {
    const params = { 'userId': userId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'fetch_user_profile_v2', params, cancelToken)
    return dataPass;
};

export const weightHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'weight_history', params, cancelToken)
    return dataPass;
};

export const hraHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'hra_history', params, cancelToken)
    return dataPass;
};

export const addWeight = (userId, weight, height, cancelToken) => {
    const params = { 'userId': userId, 'weight': weight, 'height': height }
    var dataPass = common_axios.post(baseURL + 'add_weight', params, cancelToken)
    return dataPass;
};

export const disEnrollRequest = (userId, disEnrollmentNote, disEnrollmentReason, cancelToken) => {
    const params = { 'userId': userId, 'disEnrollmentNote': disEnrollmentNote, 'disEnrollmentReason': disEnrollmentReason }
    var dataPass = common_axios.post(baseURL + 'request_disenrollment', params, cancelToken)
    return dataPass;
};

export const fetchPathways = (userId, programId, conditionId, oldPathwayId, cancelToken) => {
    const params = { 'userId': userId, 'programId': programId, 'conditionId': conditionId, 'oldPathwayId': oldPathwayId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathways', params, cancelToken)
    return dataPass;
};

export const assignPathway = (userId, programId, conditionId, oldPathwayId, pathwayId, notes, hba1c, date, cancelToken) => {
    const params = { 'userId': userId, 'programId': programId, 'conditionId': conditionId, 'oldPathwayId': oldPathwayId, 'pathwayId': pathwayId, 'notes': notes, 'hba1c': hba1c, 'date': date }
    var dataPass = common_axios.post(baseURL + 'assign_pathway', params, cancelToken)
    return dataPass;
};

export const fetchLastHRA = (userId, hraType, cancelToken) => {
    const params = { 'userId': userId, 'hraType': hraType }
    var dataPass = common_axios.post(baseURL + 'fetch_last_hra', params, cancelToken)
    return dataPass;
};

export const saveHRA = (userId, hraJson, hraType, hraScore, hraObservation, hraVersion, cancelToken) => {
    const params = { 'userId': userId, 'hraJson': hraJson, 'hraType': hraType, 'hraScore': hraScore, 'hraObservation': hraObservation, 'hraVersion': hraVersion }
    var dataPass = common_axios.post(baseURL + 'save_hra', params, cancelToken)
    return dataPass;
};

export const fetchMemberServices = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_services', params, cancelToken)
    return dataPass;
};

export const fetchMemberServicesMonthCategoryWise = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_services_month_category_wise_v2', params, cancelToken)
    return dataPass;
};

export const fetchLabs = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_labs', params, cancelToken)
    return dataPass;
};

export const bookMemberConditionServices = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'book_member_condition_services', params, cancelToken)
    return dataPass;
};

export const updateMemberConditionServicesStatus = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'update_member_condition_services_status', params, cancelToken)
    return dataPass;
};

export const bookMemberServices = (userId, recordId, serviceType, formData, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'book_member_services', params, cancelToken)
    return dataPass;
};

export const updateMemberServicesStatus = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'update_member_services_status', params, cancelToken)
    return dataPass;
};

export const fetchServiceNotes = (userId, recordId, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'fetch_service_notes', params, cancelToken)
    return dataPass;
};


export const fetchNotifications = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_notifications', params, cancelToken)
    return dataPass;
};

export const fetchNotificationsList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_notifications_list', params, cancelToken)
    return dataPass;
};

export const updateNotifications = (notificationId, cancelToken) => {
    const params = { 'notificationId': notificationId }
    var dataPass = common_axios.post(baseURL + 'update_notifications', params, cancelToken)
    return dataPass;
};

export const fetchMemberConditionServices = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_condition_services', params, cancelToken)
    return dataPass;
};

export const fetchMemberPersonalData = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_personal_data', params, cancelToken)
    return dataPass;
};

export const checkMembership = (membershipNo, cancelToken) => {
    const params = { 'membershipNo': membershipNo }
    var dataPass = common_axios.post(baseURL + 'check_member_details', params, cancelToken)
    return dataPass;
};

export const saveNewMember = (formData, memberType, cancelToken) => {
    const params = { 'formData': formData, 'memberType': memberType }
    var dataPass = common_axios.post(baseURL + 'save_new_member', params, cancelToken)
    return dataPass;
};

export const memberSearchJourney = (payload, formData, cancelToken) => {
    const params = { 'payload': payload, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'member_search_journey', params, cancelToken)
    return dataPass;
};

export const saveMedication = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_medication_data', params, cancelToken)
    return dataPass;
};

export const saveMedicationMetaDoc = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_medication_data_metadoc', params, cancelToken)
    return dataPass;
};

export const fetchFormData = (cityId, membership, cancelToken) => {
    const params = { 'cityId': cityId, 'membership': membership }
    var dataPass = common_axios.post(baseURL + 'fetch_form_data', params, cancelToken)
    return dataPass;
};

export const saveLabRequest = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_lab_request', params, cancelToken)
    return dataPass;
};

export const saveLabRequestMetaDoc = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_lab_request_metadoc', params, cancelToken)
    return dataPass;
};

export const fetchFormDataBase = (membership, cancelToken) => {
    const params = { 'membership': membership }
    var dataPass = common_axios.post(baseURL + 'fetch_home_base', params, cancelToken)
    return dataPass;
};

export const fetchFormDataMedication = (cityId, membership, cancelToken) => {
    const params = { 'cityId': cityId, 'membership': membership }
    var dataPass = common_axios.post(baseURL + 'fetch_medication_form', params, cancelToken)
    return dataPass;
};

export const fetchHomeCare = (categoryName, cityName, membership, cancelToken) => {
    const params = { 'categoryName': categoryName, 'cityName': cityName, 'membership': membership }
    var dataPass = common_axios.post(baseURL + 'fetch_home_care', params, cancelToken)
    return dataPass;
};

export const saveHomeCareRequest = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_care', params, cancelToken)
    return dataPass;
};

export const saveHomeCareRequestMetaDoc = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_care_metadoc', params, cancelToken)
    return dataPass;
};

export const saveHomeBase = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_base', params, cancelToken)
    return dataPass;
};

export const saveHomeBaseMetaDoc = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_base_metadoc', params, cancelToken)
    return dataPass;
};

export const fetch_soft_number = (data) => {
    return common_axios.post(baseURL + 'fetch_soft_number', data);
}

export const fetchsoftphonetoken = (data) => {
    return common_axios.post(baseURL + 'fetchsoftphonetoken', data);
}

export const make_soft_phone_call = (data) => {
    return common_axios.post(baseURL + 'make_soft_phone_call', data);
}

export const cn_call_disconnect = (data) => {
    return common_axios.post(baseURL + 'cn_call_disconnect', data);
}

export const cn_call_hold = (data) => {
    return common_axios.post(baseURL + 'cn_call_hold', data);
}

export const cn_call_unhold = (data) => {
    return common_axios.post(baseURL + 'cn_call_unhold', data);
}

export const cn_call_user_status = (data) => {
    return common_axios.post(baseURL + 'cn_call_user_status', data);
}

export const soft_number_logger = (data) => {
    return common_axios.post(baseURL + 'soft_number_logger', data);
}

export const fetchServiceCategories = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_service_categories', params, cancelToken)
    return dataPass;
};

export const fetchServiceByCategory = (categoryId, cancelToken) => {
    const params = { 'categoryId': categoryId }
    var dataPass = common_axios.post(baseURL + 'fetch_service_by_category', params, cancelToken)
    return dataPass;
};

export const addMemberAdditionalServices = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_member_additional_services', params, cancelToken)
    return dataPass;
};

export const addMemberConditionServices = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_member_condition_services', params, cancelToken)
    return dataPass;
};


export const cn_call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'cn_call_disposition', data);
    return dataPass;
}

export const fetchLastAssessment = (userId, version, cancelToken) => {
    const params = { 'userId': userId, 'version': version }
    var dataPass = common_axios.post(baseURL + 'fetch_last_assessment', params, cancelToken)
    return dataPass;
};

export const data_disposition_list = () => {
    let dataPass = common_axios.get(baseURL + 'data_disposition_list');
    return dataPass;
}

export const fetchsoftphonetokencn = (data) => {
    let dataPass = common_axios.post(baseURL + 'fetchsoftphonetokencn', data);
    return dataPass;
}

export const clearfirebasecnnode = (data) => {
    let dataPass = common_axios.post(baseURL + 'clearfirebasecnnode', data);
    return dataPass;
}

export const fetchcn = () => {
    let dataPass = common_axios.get(baseURL + 'fetchcn');
    return dataPass;
}

export const pathwayBreakup = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'pathway_breakup', params, cancelToken)
    return dataPass;
};


export const cn_call_mute = (data) => {
    var dataPass = common_axios.post(baseURL + 'cn_call_mute', data);
    return dataPass;
}

export const fetchReportTypes = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_report_types', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFiles = (userId, reportTypeId, callId, cancelToken) => {
    const params = { 'userId': userId, 'reportTypeId': reportTypeId, 'callId': callId }
    var dataPass = common_axios.post(baseURL + 'fetch_healthlocker_files_v2', params, cancelToken)
    return dataPass;
};

export const uploadEdgeFile = (selectedData, bookingId, callId, userId, membershipNo, cancelToken) => {
    const params = { 'selectedData': selectedData, 'bookingId': bookingId, 'callId': callId, 'userId': userId, 'membershipNo': membershipNo }
    var dataPass = common_axios.post(baseURL + 'upload_edge_file', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFileUrl = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'fetch_healthlocker_file_url', params, cancelToken)
    return dataPass;
};

export const fetchHealthLockerFileInfo = (userId, fileId, cancelToken) => {
    const params = { 'userId': userId, 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'fetch_healthlocker_file_info', params, cancelToken)
    return dataPass;
};

export const removeHealthLockerFiles = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'remove_healthlocker_files', params, cancelToken)
    return dataPass;
};

export const addHealthLockerFile = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const updateHealthlockerFile = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'update_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const fetchPathwayChangeLogs = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathway_change_logs', params, cancelToken)
    return dataPass;
};


export const services_action = (data) => {
    const dataPass = common_axios.post(baseURL + 'services_action', data);
    return dataPass;
}

export const fetchServiceEventHistory = (userId, pathwayId, cancelToken) => {
    const params = { 'userId': userId, 'pathwayId': pathwayId }
    var dataPass = common_axios.post(baseURL + 'fetch_service_event_history', params, cancelToken)
    return dataPass;
};

export const fetchPathwayServicesMonthCategoryWise = (userId, pathwayId, cancelToken) => {
    const params = { 'userId': userId, 'pathwayId': pathwayId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathway_services_month_category_wise_v2', params, cancelToken)
    return dataPass;
};

export const searchExternalMembers = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'search_external_members_v2', params, cancelToken)
    return dataPass;
};

export const fetchDoctorList = (formData,membershipNo, currentPage, rowsPerPage, cancelToken) => {
    const params = { 'formData': formData, 'membershipNo': membershipNo, 'currentPage': currentPage, 'rowsPerPage': rowsPerPage }
    var dataPass = common_axios.post(baseURL + 'fetch_doctor_list_edge', params, cancelToken)
    return dataPass;
};

export const memberDetailsEdge = (membershipNo, userId, cancelToken) => {
    const params = { 'membershipNo': membershipNo, 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'member_details_edge', params, cancelToken)
    return dataPass;
};

export const fetchDoctorSlots = (doctorId, date, membershipNo, cancelToken) => {
    const params = { 'doctorId': doctorId, 'date': date, 'membershipNo': membershipNo }
    var dataPass = common_axios.post(baseURL + 'fetch_doctor_slots', params, cancelToken)
    return dataPass;
};

export const bookMemberAppointment = (doctorId, doctorName, date, time, membershipNo, userId, bookingNotes, patientId, cancelToken) => {
    const params = { 'doctorId': doctorId, 'doctorName': doctorName, 'date': date, 'time': time, 'membershipNo': membershipNo, 'userId': userId, 'bookingNotes': bookingNotes, 'patientId': patientId }
    var dataPass = common_axios.post(baseURL + 'book_member_appointment', params, cancelToken)
    return dataPass;
};

export const fetchEmptyCalendarSlots = (date, cancelToken) => {
    const params = { 'date': date }
    var dataPass = common_axios.post(baseURL + 'fetch_empty_calendar_slots', params, cancelToken)
    return dataPass;
};

export const scheduleCarenavigatorCall = (userId, date, cancelToken) => {
    const params = { 'userId': userId, 'date': date }
    var dataPass = common_axios.post(baseURL + 'schedule_carenavigator_call', params, cancelToken)
    return dataPass;
};

export const fetchHomeBasedServicesHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_home_based_services_history', params, cancelToken)
    return dataPass;
};

export const fetchServiceLogHistory = (serviceType, serviceId, cancelToken) => {
    const params = { 'serviceType': serviceType, 'serviceId': serviceId }
    var dataPass = common_axios.post(baseURL + 'fetch_service_log_history', params, cancelToken)
    return dataPass;
};

export const updateAltMobile = (userId, updateRequest, mobile, altMobile, isPrimaryWhatsApp, selectedLanguage, cancelToken) => {
    const params = { 'userId': userId, 'updateRequest': updateRequest, 'mobile': mobile, 'altMobile': altMobile, 'isPrimaryWhatsApp': isPrimaryWhatsApp, 'selectedLanguage': selectedLanguage }
    var dataPass = common_axios.post(baseURL + 'update_alt_mobile', params, cancelToken)
    return dataPass;
};

export const fetchCallStatus = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_call_status', params, cancelToken)
    return dataPass;
};

export const fetchUserSpecificCalls = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_user_specific_calls', params, cancelToken)
    return dataPass;
};

export const updateCallStatus = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'update_call_status', params, cancelToken)
    return dataPass;
};


export const updateWebexCallStatus = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'update_call_status_webex', params, cancelToken)
    return dataPass;
};

export const updateHomeBasedServiceStatus = (serviceId, serviceType, status, cancelToken) => {
    const params = { 'serviceId': serviceId, 'serviceType': serviceType, 'status': status }
    var dataPass = common_axios.post(baseURL + 'update_home_based_service_status', params, cancelToken)
    return dataPass;
};

export const rescheduleHomeBaseService = (serviceIdNew, serviceTypeNew,newCallDateSend, cancelToken) => {
    const params = { 'serviceIdNew': serviceIdNew, 'serviceTypeNew': serviceTypeNew, 'newCallDateSend': newCallDateSend }
    var dataPass = common_axios.post(baseURL + 'reschedule_homebase_service', params, cancelToken)
    return dataPass;
};

export const fetchHomeBasedServiceTracking = (membershipNumber, requestType, requestStartDate, requestEndDate, requestStatus, cancelToken) => {
    const params = { 'membershipNumber': membershipNumber, 'requestType': requestType, 'requestStartDate': requestStartDate, 'requestEndDate': requestEndDate, 'requestStatus': requestStatus }
    var dataPass = common_axios.post(baseURL + 'fetch_home_based_service_tracking_v2', params, cancelToken)
    return dataPass;
};

export const fetchDisenrollmentReasons = (userId, programId, conditionId, cancelToken) => {
    const params = { 'userId': userId, 'programId': programId, 'conditionId': conditionId }
    var dataPass = common_axios.post(baseURL + 'fetch_disenrollment_reasons', params, cancelToken)
    return dataPass;
};

export const bulkServicesAction = (serviceIdArr, serviceType, status, cancelToken) => {
    const params = { 'serviceIdArr': serviceIdArr, 'serviceType': serviceType, 'status': status }
    var dataPass = common_axios.post(baseURL + 'bulk_services_action', params, cancelToken)
    return dataPass;
};

export const fetchServiceNameByRecordId = (serviceIdArr, cancelToken) => {
    const params = { 'serviceIdArr': serviceIdArr }
    var dataPass = common_axios.post(baseURL + 'fetch_service_name_by_recordid', params, cancelToken)
    return dataPass;
};

export const addNotes = (userId, notes, cancelToken) => {
    const params = { 'userId': userId, 'notes': notes }
    var dataPass = common_axios.post(baseURL + 'add_notes', params, cancelToken)
    return dataPass;
};

export const fetchNotesList = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_notes_list', params, cancelToken)
    return dataPass;
};

export const fetchProgramConditionPathway = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_program_condition_pathway', params, cancelToken)
    return dataPass;
};

export const updateMemberFromEdge = (userId, membershipNumber, cancelToken) => {
    const params = { 'userId': userId, 'membershipNumber': membershipNumber }
    var dataPass = common_axios.post(baseURL + 'member_update_from_edge', params, cancelToken)
    return dataPass;
};

export const addNewProgram = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_new_program', params, cancelToken)
    return dataPass;
};

export const check_webex_enable = () => {
    var dataPass = common_axios.get(baseURL + 'check_webex_enable')
    return dataPass
}
export const stoppingPathway = (userId, pathwayId, pathwayRelId, remarks, cancelToken) => {
    const params = { 'userId': userId, 'pathwayId': pathwayId, 'pathwayRelId': pathwayRelId, 'remarks': remarks }
    var dataPass = common_axios.post(baseURL + 'stop_pathway', params, cancelToken)
    return dataPass;
};

export const restartingPathway = (userId, pathwayId, pathwayRelId, remarks, cancelToken) => {
    const params = { 'userId': userId, 'pathwayId': pathwayId, 'pathwayRelId': pathwayRelId, 'remarks': remarks }
    var dataPass = common_axios.post(baseURL + 'restart_pathway', params, cancelToken)
    return dataPass;
};

export const cancel_or_reschedule_appointment = (data) => {
    var dataPass = common_axios.post(baseURL + 'cancel_or_reschedule_appointment', data)
    return dataPass;
}

export const fetch_chat = (userId, page) => {
    const params = { 'id': userId, 'page': page }
    var dataPass = common_axios.post(baseURL + 'fetch_chat', params)
    return dataPass;
};

export const update_status = (params) => {
    var dataPass = common_axios.post(baseURL + 'update_status', params)
    return dataPass;
};

export const check_status = (id) => {
    const params = { 'id': id }
    var dataPass = common_axios.post(baseURL + 'check_status', params)
    return dataPass;
};

export const sendTemplateApi = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp_send', params)
    return dataPass;
};

export const send_message = (message, id) => {
    const params = { 'message': message, 'id': id }
    var dataPass = common_axios.post(baseURL + 'send_message', params)
    return dataPass;
};
export const send_message_v1 = (params) => {
    var dataPass = common_axios.post(baseURL + 'send_message_v1', params)
    return dataPass;
};

export const whatsapp_list = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp_list', params)
    return dataPass;
};

export const member_details = (params) => {
    var dataPass = common_axios.post(baseURL + 'member_details', params)
    return dataPass;
};

export const addMetricsVals = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_metrics_vals', params, cancelToken)
    return dataPass;
};

export const removePathway = (userId, pathwayRelId, cancelToken) => {
    const params = { 'userId': userId, 'pathwayRelId': pathwayRelId }
    var dataPass = common_axios.post(baseURL + 'remove_pathway', params, cancelToken)
    return dataPass;
};

export const fetchGenericHealthMetrics = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_generic_health_metrics', params, cancelToken)
    return dataPass;
};

export const addGenericMetricsVals = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_generic_metrics_vals', params, cancelToken)
    return dataPass;
};

export const addMetricsWeight = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_metrics_weight', params, cancelToken)
    return dataPass;
};

export const memberContentNabed = (membershipNo, userId, requestFrom, cancelToken) => {
    const params = { 'membershipNo': membershipNo, 'userId': userId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'member_content_nabed', params, cancelToken)
    return dataPass;
};

export const memberAssignJourneyPreview = (payload, cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'member_assign_journey_preview', payload, cancelToken)
    return dataPass;
};

export const memberAssignJourney = (payload, cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'member_assign_journey', payload, cancelToken)
    return dataPass;
};

export const fetchNewReports = (membershipNumber, reportTypeId, requestStartDate, requestEndDate, pageNumber, cancelToken) => {
    const params = { 'membershipNumber': membershipNumber, 'reportTypeId': reportTypeId, 'requestStartDate': requestStartDate, 'requestEndDate': requestEndDate, 'pageNumber': pageNumber }
    var dataPass = common_axios.post(baseURL + 'fetch_new_reports', params, cancelToken)
    return dataPass;
};
export const updateTeleHealthService = (statusType, bookingId, callId, date, time, bookingNotes, cancelToken) => {
    const params = { 'statusType': statusType, 'bookingId': bookingId, 'callId': callId, 'date': date, 'time': time, 'bookingNotes': bookingNotes }
    var dataPass = common_axios.post(baseURL + 'update_telehealth_service', params, cancelToken)
    return dataPass;
};

export const fetchMember360Profile = (membershipNumber) => {
    const params = { 'membershipNumber': membershipNumber }
    var dataPass = common_axios.post(baseURL + 'fetch_member_360profile', params);
    return dataPass;
};

export const fetchCallDetails = (callId, cancelToken) => {
    const params = { 'callId': callId }
    var dataPass = common_axios.post(baseURL + 'fetch_call_details', params, cancelToken)
    return dataPass;
};

export const fetchUpcomingAppointmentsList = (membershipNumber, startDate, endDate, pageNumber, cancelToken) => {
    const params = { 'membershipNumber': membershipNumber, 'startDate': startDate, 'endDate': endDate, 'pageNumber': pageNumber }
    var dataPass = common_axios.post(baseURL + 'fetch_upcoming_appointments_list', params, cancelToken)
    return dataPass;
};
