import React from 'react'
import ConnectContactList from './ConnectContactList';
import ConnectChatArea from './ConnectChatArea';
import ConnectUserDetails from './ConnectUserDetails';
import { InboxProvider } from './ConnectInboxContext';

const ConnectInbox = () => {


  return (
    <InboxProvider>
      <div className='mb-20 container-fluid'>
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
  <div className="tw-w-full tw-pb-3 tw-pt-1">
    <div className="tw-flex tw-items-center tw-justify-between">
      <h4 className="tw-text-gray-600 tw-m-0 tw-font-semibold">MemberConnect Inbox</h4>
      
    </div>
  </div>
</div>
      <div className="tw-flex tw-h-[73vh] tw-p-1  tw-overflow-y-auto tw-bg-white tw-rounded-3xl tw-transition-all tw-duration-300">
        {/* Left Sidebar - Contact List */}
        <div className="tw-w-1/4 tw-border-r tw-border-gray-300 tw-relative tw-z-0">
          <ConnectContactList />
        </div>

        <div className='vr'></div>
        
        {/* Main Chat Area */}
        <div className="tw-w-1/2 tw-flex tw-flex-col">
          <ConnectChatArea/>
        </div>

        <div className='vr'></div>

        
        {/* Right Sidebar - User Details */}
        <div className="tw-w-1/4 tw-border-l tw-border-gray-300 tw-overflow-y-auto" >
          <ConnectUserDetails />
        </div>
      </div>
      </div>
    </InboxProvider>
  )
}
export default ConnectInbox