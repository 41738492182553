import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import firebase from "../../../../firebase/firebase";

const InboxContext = createContext();
export const useInboxContext = () => useContext(InboxContext);

export const InboxProvider = ({ children }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchMode, setSearchMode] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [family, setFamily] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("all");
  const [search, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("Newest");
  const [showkeyboard, setShowKeyBoard] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [optOut, setOptOut] = useState(false);

  const [chatPage, setChatpage] = useState(1);
  const [chatResult, setChatResult] = useState([]);
  const [loadingMore, setLoadingMore] = useState(true);
  const [changeFilterState, setchangeFilterState] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentlySelectedContact, setCurrentlySelectedContact] =
    useState(null);
  const [initialMessageCount, setInitialMessageCount] = useState(0);

  const [cnOptions, setCnOptions] = useState([]);
  const [selectedCN, setSelectedCN] = useState([]);
  const [cnFilter, setCnFilter] = useState(false);

  // Add new state for Firebase
  const [firebaseListeners, setFirebaseListeners] = useState({});
  const [newMessageAlert, setNewMessageAlert] = useState(false);
  const [lastMessageData, setLastMessageData] = useState(null);
  const [lastStatusData, setLastStatusData] = useState(null);

  // Register refresh functions from components
  const [refreshChat, setRefreshChat] = useState(null);
  const [refreshContacts, setRefreshContacts] = useState(null);

  const [firebaseAlert, setFirebaseAlert] = useState(null);

  // Reference to component refresh functions
  const chatRefreshRef = useRef(null);
  const contactsRefreshRef = useRef(null);

  // Get today's date in YYYYMMDD format
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(today.getDate()).padStart(2, "0");
  const dateString = `${year}${month}${day}`;

  // Use dynamic date in Firebase path
  // const baseFirebasePath = `ksa-bupa-test/whatsappadmin/${dateString}`;

  console.log(selectedContact);

  console.log("Chat result", chatResult);

  const baseFirebasePath = selectedContact
    ? selectedContact[0].firbasenode
    : `ksa-bupa-test/whatsappadmin/${dateString}`;

  const resetSelectedContact = () => {
    setSelectedContact(null);
  };

  // Functions to be called from components to register their refresh functions
  const registerChatRefresh = (fn) => {
    console.log("Chat refresh function registered");
    chatRefreshRef.current = fn;
  };

  const registerContactsRefresh = (fn) => {
    console.log("Contacts refresh function registered");
    contactsRefreshRef.current = fn;
  };

  // Handle new messages from Firebase
  const handleNewMessage = (data) => {
    if (!data || !data.userId) return;

    console.log("Firebase new message received:", data);
    console.log("Current selectedContact:", selectedContact);
    setLastMessageData(data);

    // Update contacts list with new message info
    setContacts((prevContacts) => {
      return prevContacts.map((contact) => {
        if (contact.id === data.userId) {
          const updates = {
            unreadCount: (contact.unreadCount || 0) + 1,
          };

          if (data.messageText) updates.messageText = data.messageText;
          if (data.messageTime) updates.messageTime = data.messageTime;
          if (data.msgType) updates.msgType = data.msgType;

          return { ...contact, ...updates };
        }
        return contact;
      });
    });

    // Increment overall unread count
    setUnreadCount((prev) => parseInt(prev) + 1);

    // Show new message alert
    setNewMessageAlert(true);
    setTimeout(() => {
      setNewMessageAlert(false);
    }, 3000);

    // Call the contacts refresh function
    if (typeof contactsRefreshRef.current === "function") {
      console.log("Calling contacts refresh function");
      contactsRefreshRef.current();
    } else {
      console.warn("Contacts refresh function is not registered");
    }

    // Store the userId in a variable
    const newMessageUserId = data.userId;

    // For the chat refresh, we need to check:
    // 1. If selectedContact is available now
    // 2. If the message is for the current selected contact
    if (
      selectedContact &&
      selectedContact[0] &&
      selectedContact[0].userId === newMessageUserId &&
      typeof chatRefreshRef.current === "function"
    ) {
      console.log("Calling chat refresh function immediately");
      chatRefreshRef.current();
    } else {
      // If selectedContact is not available or doesn't match,
      // set up a small delay to check again (giving time for selectedContact to update)
      console.log("Scheduling delayed chat refresh check");
      setTimeout(() => {
        console.log("Delayed check - selectedContact:", selectedContact);
        if (
          selectedContact &&
          selectedContact[0] &&
          selectedContact[0].userId === newMessageUserId &&
          typeof chatRefreshRef.current === "function"
        ) {
          console.log("Calling chat refresh function after delay");
          chatRefreshRef.current();
        } else {
          console.log(
            "Not refreshing chat - contact not selected or function not registered"
          );
        }
      }, 500); // Give it half a second for the selectedContact to update
    }
  };

  // Handle message status updates from Firebase
  const handleStatusUpdate = (data) => {
    if (!data || !data.userId || !data.twId || !data.status) return;

    console.log("Firebase status update received:", data);
    setLastStatusData(data);

    // Update message status in chat results if this is the active chat
    if (
      selectedContact &&
      selectedContact[0] &&
      selectedContact[0].userId === data.userId
    ) {
      setChatResult((prevChatResult) => {
        return prevChatResult.map((message) => {
          if (message.messageId === data.twId) {
            return { ...message, messageStatus: data.status };
          }
          return message;
        });
      });
    }
  };

  // Set up main Firebase listeners on component mount
  useEffect(() => {
    // Skip if we've already set up listeners
    if (Object.keys(firebaseListeners).length > 0) return;

    try {
      const database = getDatabase(firebase);

      // New message listener
      const newMessageRef = ref(database, `${baseFirebasePath}/newmessage`);
      const newMessageUnsubscribe = onValue(newMessageRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          handleNewMessage(data);
          setFirebaseAlert(data);
        }
      });

      // Status update listener
      const statusRef = ref(database, `${baseFirebasePath}/status`);
      const statusUnsubscribe = onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        if (data) handleStatusUpdate(data);
      });

      // Store the unsubscribe functions
      setFirebaseListeners({
        newMessage: newMessageUnsubscribe,
        status: statusUnsubscribe,
      });

      console.log("Firebase listeners set up successfully");
    } catch (error) {
      console.error("Failed to set up Firebase listeners:", error);
    }

    // Clean up listeners on unmount
    return () => {
      Object.values(firebaseListeners).forEach((unsubscribe) => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
          setFirebaseAlert(null);
        }
      });
      console.log("Firebase listeners cleaned up");
    };
  }, []);

  // console.log(currentlySelectedContact, "currentlySelectedContact");
  console.log(selectedContact, "selectedContact");

  return (
    <InboxContext.Provider
      value={{
        contacts,
        selectedContact,
        setSelectedContact,
        searchMode,
        setSearchMode,
        filterMode,
        setFilterMode,
        selectionMode,
        setSelectionMode,
        selectedContacts,
        setSelectedContacts,
        currentPage,
        setCurrentPage,
        hasMore,
        loading,
        family,
        setFamily,
        status,
        setStatus,
        setContacts,
        search,
        setSearchTerm,
        sortOrder,
        setSortOrder,
        resetSelectedContact,
        setShowKeyBoard,
        chatResult,
        setChatResult,
        chatPage,
        setChatpage,
        loadingMore,
        setLoadingMore,
        setHasMore,
        setchangeFilterState,
        changeFilterState,
        setLoading,
        unreadCount,
        setUnreadCount,
        setCurrentlySelectedContact,
        currentlySelectedContact,
        showkeyboard,
        setInitialMessageCount,
        initialMessageCount,
        setTemplateStatus,
        templateStatus,
        setOptOut,
        optOut,
        cnOptions,
        setCnOptions,
        selectedCN,
        setSelectedCN,
        cnFilter,
        setCnFilter,

        // New Firebase-related values
        newMessageAlert,
        setNewMessageAlert,
        lastMessageData,
        lastStatusData,

        // Set functions to register refresh callbacks
        setRefreshChat,
        setRefreshContacts,

        registerChatRefresh,
        registerContactsRefresh,

        firebaseAlert,
      }}
      className="tw-mb-16"
    >
      {children}
    </InboxContext.Provider>
  );
};
